import React, { useContext, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getCookie, setCookie } from '../../../../../utils/cookie'
import { THEME_BUTTON_TYPES } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Theme/ThemeWrapper'
import MorCartContext, {
  IDeliveryMethodCookieType,
  IMorCartContextProviderProps,
} from '../../../Context/MorCartContext'
import css from './MiniCart.scss'
import { ICartItem } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Interfaces/Strapi/Cart'
import { IMiniCartItemProps } from './MiniCartItem'
import LoadingIndicator, { ILoadingIndicatorType } from '../../../../Common/LoadingIndicator/LoadingIndicator'
import { IUnbxdProduct } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import { getProductFinalPrice } from '../../../../Utils/productUtils'
import { ILocationCookieType } from '../../../../StoreLocator/interfaces'
import ThemedButton from '../../../../Common/Button/Button'

const MiniCartItem = dynamic<IMiniCartItemProps>(() => import('./MiniCartItem'))

interface IMiniCartProps {
  showMiniCart: boolean
  onClose: (evt: React.SyntheticEvent<HTMLElement>) => void
  onRetrieveCartModal: () => void
  onRemoveItem: (item: ICartItem) => void
  pageScrolled: boolean
}

const MiniCart = (props: IMiniCartProps) => {
  const { cart } = useContext(MorCartContext.Context) as IMorCartContextProviderProps

  const { showMiniCart, onClose, onRetrieveCartModal, onRemoveItem, pageScrolled } = props

  const [storeCode, setStoreCode] = useState<string>('')
  const [cartPrice, setCartPrice] = useState<number>(0)

  useEffect(() => {
    try {
      const cookieVal: string | undefined = getCookie(ILocationCookieType.STORE_CODES, null)
      const pickupStoreCode = getCookie(IDeliveryMethodCookieType.PICKUP_STORE_CODE)
      let storeCode = 'XX'
      if (cookieVal) {
        storeCode = pickupStoreCode && pickupStoreCode !== 'null' ? pickupStoreCode : JSON.parse(cookieVal).storeCode
        setStoreCode(storeCode)
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    let subTotal: number = 0
    for (const cartItem of cart) {
      const priceOverride = cartItem?.metaData?.priceOverride?.price
      const product = cartItem.product as IUnbxdProduct
      let itemPrice = getProductFinalPrice(product, storeCode) * cartItem.quantity
      if (priceOverride) {
        itemPrice = priceOverride * cartItem.quantity
      }
      subTotal += itemPrice
    }
    setCartPrice(subTotal)
  }, [cart])

  const redirectToCheckout = () => {
    const deliveryMethodType = getCookie(IDeliveryMethodCookieType.DELIVERY_TYPE, null)
    if (!deliveryMethodType) {
      setCookie(IDeliveryMethodCookieType.DELIVERY_TYPE, 'D')
    }
    location.href = '/checkout'
  }

  return (
    <div className={`${css.miniCart} ${showMiniCart && css.showMiniCart} ${pageScrolled && css.miniCartScrolled}`}>
      <span
        className={css.close}
        title="Close"
        onClick={onClose}
        onKeyPress={onClose}
        tabIndex={0}
        role="button"
        aria-label="Close"
      >
        <FontAwesomeIcon icon={faTimes} className={css.exitIcon} />
      </span>
      <div className={css.cartHeader}>Your Cart</div>
      <div className={css.cartItems}>
        {cart.length > 0 ? (
          <>
            {cart.map((item: any, index: number) => {
              return <MiniCartItem key={index} item={item} index={index} onRemoveItem={onRemoveItem} />
            })}
            <div className={css.bottomMessages}>
              <div className={css.subTotal}>
                <div>
                  <b>
                    Subtotal:{' '}
                    <span>
                      {cartPrice && cartPrice > 0 ? (
                        `$${cartPrice.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`
                      ) : (
                        <LoadingIndicator type={ILoadingIndicatorType.COMPONENT} />
                      )}
                    </span>
                  </b>{' '}
                </div>
              </div>
            </div>

            <div className={css.cartActions} onClick={onClose}>
              <ThemedButton
                className={css.miniCartButton}
                type={THEME_BUTTON_TYPES.SECONDARY}
                onClick={() => (location.href = '/cart')}
              >
                REVIEW CART
              </ThemedButton>
              <ThemedButton
                className={css.miniCartButton}
                type={THEME_BUTTON_TYPES.PRIMARY}
                onClick={redirectToCheckout}
              >
                CHECKOUT
              </ThemedButton>
            </div>
          </>
        ) : (
          <div className={css.emptyCart}>
            <div className={css.emptyCartDescription} tabIndex={0}>
              You have no items in your shopping cart.
            </div>
            <div>
              Have a saved cart? Click{' '}
              <a
                className={css.retrieveCartLink}
                onClick={onRetrieveCartModal}
                tabIndex={0}
                aria-label="Have a saved cart? click here to retrieve it."
              >
                here
              </a>{' '}
              to retrieve it.
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MiniCart

import { AxiosResponse } from 'axios'
import {
  CART_COLLECTION_TYPE,
  ICartCollection,
  ICartItem,
} from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Cart'
import { ILocationCookieType } from '../../components/StoreLocator/interfaces'
import {
  ICalculateMorCartProps,
  ICartCookies,
  IDeliveryMethodCookieType,
  IMorCartItem,
  IMorCartPrice,
  IMorShippingType,
  IPickupDeliveryDates,
} from '../../components/Tenant/Context/MorCartContext'
import { emailRegex } from '../../components/Tenant/Layout/Cart/CartModal'
import { GERS_API_CALLS } from '../../components/Tenant/Services/GERS'
import { ICalculateCartRequest, IGersCalculateCartResponse } from '../../components/Tenant/Services/GERS/Cart/cart'
import { IPickupDeliveryType, IGersDeliveryResponse, IDates } from '../../components/Tenant/Services/GERS/Cart/delivery'
import { getSkuFromCartItem } from '../../components/Utils/customProductUtils'
import { getPickupLocation, isOutOfDeliveryArea } from '../../components/Utils/locationUtils'
import { getProductsEnabledMetadata, IProductEnabledMetadata } from '../../modules/products/productQuery'
import getDefaultCmsTransport from '../../services/api/cmsInterceptor'
import { getCookie, setCookie } from '../cookie'

interface ISalesperson {
  homeStoreCode: string
  homeStoreName: string | null
  fullName: string
  employeeCode: string
  employeeCode2: string
  customerCode: string
  employeeInit: string
}

interface ISavedCartMetadata {
  salesperson: ISalesperson[]
  customerCode: string
}

const returnCartOnSuccess = (response: IGersCalculateCartResponse, pickupDeliveryDates: IPickupDeliveryDates) => {
  return {
    error: response.error.length > 0 && response.data === null,
    shoppingCartId: response.data?.shoppingCartID,
    items: response.data ? response.data.items : [],
    salesTaxLabel: response.data ? response.data.salesTaxLabel : '',
    subtotalLabel: response.data ? response.data.subtotalLabel : '',
    pickupDeliveryLabel: response.data ? response.data.pickupDeliveryLabel : '',
    pickupDeliveryType: response.data ? response.data.pickupDeliveryType : '',
    pickupDeliveryDates,
    taxCharge: response.data ? response.data.salesTax : 0,
    recyclingCharge: response.data && response.data.recyclingFee ? response.data.recyclingFee : 0,
    recyclingFeeInfo: response.data ? response.data.recyclingFeeInfo : '',
    recyclingFeeLabel: response.data ? response.data.recyclingFeeLabel : '',
    rate: 0,
    coupon: 0,
    shipping: response.data && response.data.pickupDeliveryFees ? response.data.pickupDeliveryFees : 0,
    subTotal: response.data && response.data.subtotal ? response.data.subtotal : 0,
    orderTotal: response.data && response.data.orderTotal ? response.data.orderTotal : 0,
    tax: -1,
    promoSavings: [
      {
        label: response.data?.promo1Label,
        value: response.data?.promo1Savings,
      },
      {
        label: response.data?.promo2Label,
        value: response.data?.promo2Savings,
      },
      {
        label: response.data?.promo3Label,
        value: response.data?.promo3Savings,
      },
    ],
    promoSavingsLabel: response.data?.promoSavingsLabel,
    promoSavingsTotal: response.data?.promoSavingsTotal,
    couponSavings: [
      {
        label: response.data?.coupon1Label,
        value: response.data?.coupon1Savings,
      },
      {
        label: response.data?.coupon2Label,
        value: response.data?.coupon2Savings,
      },
      {
        label: response.data?.coupon3Label,
        value: response.data?.coupon3Savings,
      },
    ],
    couponSavingsTotal: response.data?.couponSavingsTotal,
    mattressDeliveryLabel: response.data?.mattressDeliveryLabel,
    mattressDeliverySavings: response.data?.mattressDeliverySavings,
    addPromo1Label1: response.data?.addPromo1Label1,
    addPromo1Label2: response.data?.addPromo1Label2,
    addPromo2Label1: response.data?.addPromo2Label1,
    addPromo2Label2: response.data?.addPromo2Label2,
    addPromo3Label1: response.data?.addPromo3Label1,
    addPromo3Label2: response.data?.addPromo3Label2,
    safeguardFee: response.data?.safeguardFee,
    delDiscAmount: response.data?.delDiscAmount || 0,
    taxDiscAmount: response.data?.taxDiscAmount || 0,
    customOrderSubtotal: response.data?.customOrderSubtotal || 0,
    customOrderDepAmount: response.data?.customOrderDepAmount || 0,
    errorCode: response.data?.errorCode || '200',
  }
}

const returnCartOnError = () => {
  return {
    error: true,
    items: [],
    salesTaxLabel: '',
    subtotalLabel: '',
    pickupDeliveryLabel: '',
    pickupDeliveryType: '',
    pickupDeliveryDates: null,
    taxCharge: 0,
    recyclingCharge: 0,
    recyclingFeeInfo: '',
    recyclingFeeLabel: '',
    promoSavings: [],
    couponSavings: [],
    rate: 0,
    coupon: 0,
    shipping: 0,
    subTotal: 0,
    orderTotal: 0,
    tax: -1,
    customOrderDepAmount: 0,
    delDiscAmount: 0,
    taxDiscAmount: 0,
    customOrderSubtotal: 0,
    errorCode: '500',
  }
}

const getFirstAvailableDate = (dates: IDates[], pickupDeliveryDateFromCalculateCart: string) => {
  const firstAvailableDate = dates.find(d => d.available)
  return firstAvailableDate?.date || pickupDeliveryDateFromCalculateCart
}

const getCalculateCartRequest = async (props: ICalculateMorCartProps): Promise<ICalculateCartRequest> => {
  const { deliveryMethod, cart } = props
  const geoLocationCookie = JSON.parse(getCookie(ILocationCookieType.GEOLOCATION, null))
  const storeCodeCookie = JSON.parse(getCookie(ILocationCookieType.STORE_CODES, null))
  let { zip } = geoLocationCookie
  let { storeCode } = storeCodeCookie
  const { warehouseCode } = storeCodeCookie
  const pickupDeliveryType =
    deliveryMethod === IMorShippingType.PICKUP ? IPickupDeliveryType.PICKUP : IPickupDeliveryType.DELIVERY
  let pickupStoreCode = warehouseCode

  if (pickupDeliveryType === 'P') {
    const pickupLocation = await getPickupLocation(geoLocationCookie)
    if (isOutOfDeliveryArea({ storeCode, warehouseCode })) {
      const closestStoreInfo: any = await GERS_API_CALLS.STORELOCATOR.getClosestStoreInfo(pickupLocation.zip)
      if (closestStoreInfo.data) {
        storeCode = closestStoreInfo.data.storeCode
        pickupStoreCode = closestStoreInfo.data.warehouseCode
        zip = pickupLocation.zip
      }
    } else {
      // If pickupLocationType is a Showroom, the pickupStoreCode should be equal to storeCode.
      if (pickupLocation?.locationType === 'S') {
        pickupStoreCode = pickupLocation.legacyStoreId
      }
    }
  } else {
    pickupStoreCode = null
  }

  setCookie(IDeliveryMethodCookieType.PICKUP_STORE_CODE, pickupStoreCode)

  const cartId: string | undefined = getCookie('anonId', null)

  if (!cartId || cartId.length < 2) {
    console.log(`merceCartId size must be between 2 and 30 and is: ${cartId}`)
    throw new Error(`merceCartId size must be between 2 and 30 and is: ${cartId}`)
  }

  const items = cart || []

  const itemWithAssociateMetadata = items.find(i => i?.metaData?.salesperson)

  let finalStoreCode = itemWithAssociateMetadata?.metaData?.salesperson?.[0]?.homeStoreCode || storeCode
  let finalEmployeeCode = itemWithAssociateMetadata?.metaData?.salesperson?.[0]?.employeeCode || null
  let finalEmployeeCode2 = itemWithAssociateMetadata?.metaData?.salesperson?.[1]?.employeeCode || null
  let finalCustomerCode = itemWithAssociateMetadata?.metaData?.customerCode || null

  if (itemWithAssociateMetadata?.metaData?.salesperson) {
    setCookie(ICartCookies.SAVED_CART_METADATA, itemWithAssociateMetadata.metaData)
  } else {
    const savedCartMetadataCookie = getCookie(ICartCookies.SAVED_CART_METADATA)
    if (savedCartMetadataCookie) {
      const savedCartMetadataCookieParsed: ISavedCartMetadata = JSON.parse(savedCartMetadataCookie)

      finalStoreCode = savedCartMetadataCookieParsed?.salesperson?.[0]?.homeStoreCode || storeCode
      finalEmployeeCode = savedCartMetadataCookieParsed?.salesperson?.[0]?.employeeCode || null
      finalEmployeeCode2 = savedCartMetadataCookieParsed?.salesperson?.[1]?.employeeCode || null
      finalCustomerCode = savedCartMetadataCookieParsed?.customerCode || null
    }
  }

  let zoneCode = null
  const zoneCodeCookie = getCookie(ILocationCookieType.ZONE_CODE)
  if (zoneCodeCookie) {
    zoneCode = zoneCodeCookie
  }

  const request = {
    items,
    storeCode: finalStoreCode,
    zipCode: zip,
    pickupDeliveryType,
    pickupStoreCode,
    merceCartId: cartId,
    employeeCode: finalEmployeeCode,
    employeeCode2: finalEmployeeCode2,
    customerCode: finalCustomerCode,
    isFinanced: props.isFinanced,
    methodsOfPayment: props.methodsOfPayment || [],
    zoneCode,
    contactInfo: props.contactInfo,
    saveCartDate: props.saveCartDate,
  }
  return request
}

const parseCalculateCartResponse = async (
  response: IGersCalculateCartResponse,
  request: ICalculateCartRequest,
  dontCallAvailableDeliveryDatesZoneCode?: boolean,
) => {
  const pickupDeliveryDates: IPickupDeliveryDates = {
    dates: [],
    selectedPickupDeliveryDate: '',
  }

  if (!dontCallAvailableDeliveryDatesZoneCode && response?.data?.errorCode === '200' && request.zipCode) {
    const gersDeliveryResponse: IGersDeliveryResponse = await GERS_API_CALLS.DELIVERY.getAvailableDeliveryDatesZoneCode(
      response.data.shoppingCartID.toString(),
      request.zipCode,
      40,
      response.data.pickupDeliveryDate,
      request.pickupDeliveryType,
      request.pickupStoreCode,
    )

    if (gersDeliveryResponse.data?.zone) {
      setCookie(ILocationCookieType.ZONE_CODE, gersDeliveryResponse.data.zone)
    }

    if (gersDeliveryResponse?.data?.dates) {
      pickupDeliveryDates.dates = gersDeliveryResponse.data.dates
      pickupDeliveryDates.selectedPickupDeliveryDate = getFirstAvailableDate(
        gersDeliveryResponse.data.dates,
        response.data.pickupDeliveryDate,
      )
    }
  }

  return returnCartOnSuccess(response, pickupDeliveryDates)
}

const getMorCartTotal = async (props: ICalculateMorCartProps): Promise<IMorCartPrice> => {
  try {
    const request = await getCalculateCartRequest(props)
    const response: IGersCalculateCartResponse = await GERS_API_CALLS.CART.calculateCart(request)
    return await parseCalculateCartResponse(response, request, props.dontCallAvailableDeliveryDatesZoneCode)
  } catch (e) {
    console.log(e?.response?.data || e)
    return returnCartOnError()
  }
}

const removeAllTakenWithItems = (cartItems: IMorCartItem[]) => {
  for (const [index, item] of cartItems.entries()) {
    if (item.metaData?.takenWith === 'Y') {
      const newItem: any = { ...item } // Object destructured to avoid mutating state
      newItem.metaData.takenWith = 'N'
      cartItems[index] = newItem
    }
  }
  return cartItems
}

const removeAllPriceOverrideItems = (cartItems: IMorCartItem[]) => {
  const now = new Date() // Current local date and time
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()) // Strip time from `now`

  for (const [index, item] of cartItems.entries()) {
    if (item.metaData?.priceOverride?.date) {
      const priceOverrideDate = new Date(item.metaData.priceOverride.date) // Parse the date
      const overrideDay = new Date(
        priceOverrideDate.getFullYear(),
        priceOverrideDate.getMonth(),
        priceOverrideDate.getDate(),
      ) // Strip time

      // Check if the price override date has passed midnight
      if (today > overrideDay) {
        const newItem: IMorCartItem = { ...item } // Clone the item to avoid mutating state
        newItem.priceOverride = null // Remove the price override
        delete newItem.metaData?.priceOverride // Clean up metadata
        cartItems[index] = newItem // Replace the updated item in the array
      }
    }
  }
  return cartItems
}

const removeInactiveItems = async (cartItems: IMorCartItem[]) => {
  const skus = cartItems.map(item => getSkuFromCartItem(item))
  const enabledMetadata = await getProductsEnabledMetadata(skus)
  if (enabledMetadata?.data?.data?.products) {
    const enabledItems = cartItems.filter(cartItem => {
      return enabledMetadata.data.data.products?.some((item: IProductEnabledMetadata) => {
        return item.sku === cartItem.product.sku && item.enabled
      })
    })
    return enabledItems
  }
  return cartItems
}

const retrieveSavedCart = async (
  emailAddress: string,
  onCartItemsRetrieved: (newItems: ICartItem[], email: string) => Promise<void>,
) => {
  let existingCollectionId = null
  if (emailRegex.test(emailAddress)) {
    const emailLowerCase = emailAddress.toLowerCase()
    try {
      const existingCollectionResponse: AxiosResponse = await getDefaultCmsTransport(false).get(
        `/cartcollections?email=${emailLowerCase}&type=${CART_COLLECTION_TYPE.SAVE_FOR_LATER}`,
      )
      existingCollectionId = existingCollectionResponse?.data?.[0]?.id
      if (existingCollectionId) {
        setCookie(ICartCookies.SAVED_CART_RETRIEVED, true)
        const cartCollection: ICartCollection = existingCollectionResponse.data[0]
        let cartCollectionItems = cartCollection.items
        cartCollectionItems = removeAllTakenWithItems(cartCollectionItems)
        cartCollectionItems = removeAllPriceOverrideItems(cartCollectionItems)
        cartCollectionItems = await removeInactiveItems(cartCollectionItems)

        await onCartItemsRetrieved(cartCollectionItems, emailLowerCase)
      }
    } catch (e) {
      console.log(`There was an error retrieving your cart: ${e.toString()}`)
    }
  }
  return existingCollectionId
}

const retrieveSavedCartByUiCartId = async (
  uiCartId: string,
  onCartItemsRetrieved: (newItems: ICartItem[], email: string) => Promise<void>,
) => {
  let existingCollectionId = null
  try {
    const existingCollectionResponse: AxiosResponse = await getDefaultCmsTransport(false).get(
      `/cartcollections?uiCartId=${uiCartId}&type=${CART_COLLECTION_TYPE.SAVE_FOR_LATER}`,
    )
    existingCollectionId = existingCollectionResponse?.data?.[0]?.id
    if (existingCollectionId) {
      setCookie(ICartCookies.SAVED_CART_RETRIEVED, true)
      const cartCollection: ICartCollection = existingCollectionResponse.data[0]
      let cartCollectionItems = cartCollection.items
      cartCollectionItems = removeAllTakenWithItems(cartCollectionItems)
      cartCollectionItems = removeAllPriceOverrideItems(cartCollectionItems)
      cartCollectionItems = await removeInactiveItems(cartCollectionItems)

      await onCartItemsRetrieved(cartCollectionItems, existingCollectionResponse?.data?.[0]?.email)
    }
  } catch (e) {
    console.log(`There was an error retrieving your cart: ${e.toString()}`)
  }
  return existingCollectionId
}

export {
  getMorCartTotal,
  removeAllPriceOverrideItems,
  removeAllTakenWithItems,
  getCalculateCartRequest,
  parseCalculateCartResponse,
  retrieveSavedCart,
  removeInactiveItems,
  retrieveSavedCartByUiCartId,
}
